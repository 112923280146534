import React from "react";

const Footer = () => {
    return (
<div className="w-full text-center text-gray-600 text-sm mb-5 mt-5">
  Copyright © {new Date().getFullYear()} GYB All Rights Reserved.
</div>

    );
};

export default Footer;