

export const reportsTableHeaders = [
  "Patient",
  "DOB",
  "DOA",
  "DOS",
  "Status",
  "Case Type",
  "Insurance",
  "Claim#",
  "Policy#",
  "Proceduers",
];


export const showReportStatus = [
  {
    value: "Scheduled",
    label: "Scheduled",
  },
  {
    value: "Re-Scheduled",
    label: "Re-Scheduled",
  },
  {
    value: "Completed",
    label: "Completed",
  },
  {
    value: "No Show",
    label: "No Show",
  },
  {
    value: "Confirmed",
    label: "Confirmed",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
  },
  {
    value: "Override",
    label: "Override",
  },
  {
    value: "Un Finalize",
    label: 'Un Finalize',
  },
];
