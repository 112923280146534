import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useLoader } from "./loaderService";

const Loader = () => {
  const { loading } = useLoader();

  if (!loading) return null;

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Ensure it's above other elements
      }}
    >
      <PuffLoader color="#fff" />
    </div>
  );
};

export default Loader;
