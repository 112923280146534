export const setLocalStorage = (key, obj) => {
    localStorage.setItem(key, JSON.stringify(obj));
  };
  
  export const getLocalStorage = (key) => {
    const value = localStorage.getItem(key);
  
    return value ? JSON.parse(value) : null;
  };
  
  export const deleteLocalStorage = (key) => {
    localStorage.removeItem(key);
  };
  
  export const setSessionStorage = (key, obj) => {
    sessionStorage.setItem(key, JSON.stringify(obj));
  };
  
  export const getSessionStorage = (key) => {
    const value = sessionStorage.getItem(key);
  
    return value ? JSON.parse(value) : null;
  };
  
  export const deleteSessionStorage = (key) => {
    sessionStorage.removeItem(key);
  };
  
  export const setCookie = (name, value) => {
    var d = new Date(value?.expiresOn).toUTCString();
    document.cookie = `${name || "cookie"}=${JSON.stringify(
      value
    )}; expires=${d}; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN};`;
  };
  
  export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        return JSON.parse(c.substring(nameEQ.length, c.length));
    }
    return null;
  };
  
  export const eraseCookie = (name) => {
    if (getCookie(name)) {
      document.cookie = `${
        name || "cookie"
      }=; expires=Thu, 01 Jan 1970 00:00:01 GMT"; path=/; domain=${
        process.env.REACT_APP_COOKIE_DOMAIN
      };`;
    }
  };
  
  export const saveFilters = (queryData) => {
    const storageFilters = localStorage.getItem("filters");
    const unStringifyArray = JSON.parse(storageFilters);
    const FiltersArray =
      unStringifyArray && unStringifyArray.length > 0
        ? unStringifyArray.filter(
            (item) => item?.pageName !== queryData?.pageName
          )
        : [];
    FiltersArray.push(queryData);
    localStorage.setItem("filters", JSON.stringify(FiltersArray));
  };
  
  export const getFilters = (pageName) => {
    const storageFilters = localStorage.getItem("filters");
    const unStringifyArray = JSON.parse(storageFilters);
    return unStringifyArray && unStringifyArray.length > 0
      ? unStringifyArray.find((item) => item?.pageName === pageName)
      : null;
  };
  