import React, { useEffect, useState, useRef } from "react";
import Table from "shared/Datatable";
import PatientListFilters from "./filters";
import { Link } from "react-router-dom";
import Pagination from "shared/Pagination/Pagination";
import { getPatientList } from "services/http/PatientList";
import * as moment from "moment";
import { useLoader } from "services/loaderService";
import toast from "react-hot-toast";
import { reportsTableHeaders } from "./data";
import { arrayToExcel } from "shared/exportToExcel/ArrayToExcel";
import { getLocalStorage } from "services/storage";
import { getCaseType, getCaseStatus, getInsurance } from "services/http/Dropdown";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import PatientDetailsModal from "../Visit/patientDetailsModal";
import { DocumentManager } from "../Visit/DocumentManagerModal";
import { HiOutlineDocumentText } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import endOfMonth from "date-fns/endOfMonth";

const PatientList = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [patientListData, setPatientListData] = useState([]);
  const [caseStatusData, setCaseStatusData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [casTypeData, setCasTypeData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [selectedRow, setSelectedRow] = useState({});

  const [searchValues, setSearchValues] = useState({
    searchText: '',
    selectedCaseType: '',
    selectedCaseStatus: '',
    selectedInsurance: '',
    opened: {
      fromDate: startOfMonth(addMonths(new Date(), -3)),
      toDate: endOfMonth(new Date()),
    },

    doa: null,
    dob: null,
    insurance: ''
  });

  // Ref to track initial render
  const isFirstRender = useRef(true);
  const profile = getLocalStorage('_profile')


  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setSelectedRow({})
    setIsOpen(false)
  };

  const openDocumentModal = () => setIsOpenDocument(true);
  const closeDocumentModal = () => {
    setSelectedRow({})
    setIsOpenDocument(false)
  };
  const caseTypeList = async () => {
    showLoader()
    const payload = {
      "UserUid": profile?.userUid,
    }
    try {
      let result = await getCaseType(payload);
      if (result != null) {
        if (result?.success) {
          const options = result?.response?.receiveObj?.data?.data?.caseTypesRecords?.map(c => {
            return {
              value: c.caseType, label: c.caseType
            }
          })
          setCasTypeData(options);
          hideLoader();
        } else {
          hideLoader();
          toast.error(result?.response?.receiveObj?.data?.message || "Something went wrong!");
        }
      }
      else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader();
      toast.error(error?.response?.receiveObj?.response?.data || "Something went wrong!");
      console.log(error);
    }
  }

  const caseStatusList = async () => {
    showLoader()
    const payload = {
      "UserUid": profile?.userUid,
    }
    try {
      let result = await getCaseStatus(payload);
      if (result != null) {
        if (result?.success) {
          const options = result?.response?.receiveObj?.data?.data?.map(c => {
            return {
              value: c, label: c
            }
          })
          setCaseStatusData(options);
          hideLoader();
        } else {
          hideLoader();
          toast.error(result?.response?.receiveObj?.data?.message || "Something went wrong!");
        }
      }
      else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader();
      toast.error(error?.response?.receiveObj?.response?.data || "Something went wrong!");
      console.log(error);
    }
  }

  const insuranceList = async () => {
    showLoader()
    const payload = {
      "UserUid": profile?.userUid,
    }
    try {
      let result = await getInsurance(payload);
      if (result != null) {
        if (result?.success) {
          const options = result?.response?.receiveObj?.data?.data?.map(c => {
            return {
              value: c, label: c
            }
          })
          setInsuranceData(options);
          hideLoader();
        } else {
          hideLoader();
          toast.error(result?.response?.receiveObj?.data?.message || "Something went wrong!");
        }
      }
      else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader();
      toast.error(error?.response?.receiveObj?.response?.data || "Something went wrong!");
      console.log(error);
    }
  }

  const handlePatientClick = (data) => {
    navigate(`/visitList`, { state: { caseNo: data.caseNo } })

  }

  const Cell = ({ v, bold, }) => (
    <span
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: bold ? "700" : "",
      }}
      title={v}
    >
      {v}
    </span>
  );

  const columns = [
    {
      name: "Case#",
      selector: (row) => row.caseNo,
      sortable: true,
      width: "6%",
      center: true,
      cell: (row) => (
        <>
          <Link
            data-tag="allowRowEvents"
            onClick={() => {
              setSelectedRow(row)
              openModal();
            }}
            title={'Case Details'}
            style={{ color: "blue", textDecoration: "underline" }}
            to={""}
          >
            {row.caseNo}
          </Link>
          <HiOutlineDocumentText
            data-tag="allowRowEvents"
            onClick={() => {
              setSelectedRow(row)
              openDocumentModal();
            }}
            title="Document Manager"
            style={{
              color: "cadetblue",
              cursor: "pointer",
              marginLeft: 10
            }}
            size={16}
          />
        </>

      ),
    },
    {
      name: "Patient",
      selector: (row) => row?.patientName,
      sortable: true,
      width: "14%",
      cell: (row) => (
        <div
          style={{
            color: "blue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",

          }}
          onClick={() => handlePatientClick(row)} // Replace with your click handler
        >
          <span>
            <Cell v={row.patientName} />
          </span>
        </div>
      ),
    },
    {
      name: "DOB",
      selector: (row) => row?.dob,
      sortable: true,
      width: "8%",
      cell: (row) => <Cell v={row?.dob ? moment(new Date(row?.dob)).format("MM/DD/YYYY") : ''} />,
      right: true,
    },
    {
      name: "DOA",
      selector: (row) => row?.accidentDate,
      cell: (row) => <Cell v={row?.accidentDate ? moment(new Date(row?.accidentDate)).format("MM/DD/YYYY") : ''} />,
      width: "8%",
      sortable: true,
      right: true,
    },
    {
      name: "Open",
      selector: (row) => row?.openDate,
      sortable: true,
      right: true,
      width: "8%",
      cell: (row) => <Cell v={row?.openDate ? moment(new Date(row?.openDate)).format("MM/DD/YYYY") : ''} />,
    },
    {
      name: "Case Status",
      selector: (row) => row?.caseStatus,
      sortable: true,
      width: "8%",
      cell: (row) => <Cell v={row.caseStatus} />
    },

    {
      name: "Case Type",
      selector: (row) => row?.caseType,
      sortable: true,
      width: "8%",
      cell: (row) => <Cell v={row.caseType} />
    },
    {
      name: "Referring Office",
      selector: (row) => row?.providerOfficeAddress,
      sortable: true,
      width: "10%",
      cell: (row) => <Cell v={row.providerOfficeAddress} />
    },
    {
      name: "Insurance",
      selector: (row) => row?.insuranceName,
      cell: (row) => <Cell v={row.insuranceName} />,
      width: "14%",
      sortable: true,

    },
    {
      name: "Claim#",
      selector: (row) => row?.claimNumber,
      sortable: true,
      width: "8%",
      cell: (row) => <Cell v={row.claimNumber} />
    },
    {
      name: "Policy#",
      sortable: true,
      width: "8%",
      selector: (row) => row?.policyNumber,
      cell: (row) => <Cell v={row.policyNumber} />
    },
  ];

  const getListData = async (pageIndex = 1, pageSize = 25, searchValues) => {
    showLoader()

    const payload = {
      "UserUid": profile.userUid,
      "PageIndex": pageIndex.toString(),
      "PageSize": pageSize.toString(),
      "OpenFromDate": searchValues?.opened?.fromDate ? moment(searchValues?.opened?.fromDate).format('MM-DD-YYYY') : '',
      "OpenToDate": searchValues?.opened?.toDate ? moment(searchValues?.opened?.toDate).format('MM-DD-YYYY') : '',
      "DOBFromDate": searchValues?.dob?.fromDate ? moment(searchValues?.dob?.fromDate).format('MM-DD-YYYY') : '',
      "DOBToDate": searchValues?.dob?.toDate ? moment(searchValues?.dob?.toDate).format('MM-DD-YYYY') : '',
      "AccidentFromDate": searchValues?.doa?.fromDate ? moment(searchValues?.doa?.fromDate).format('MM-DD-YYYY') : '',
      "AccidentToDate": searchValues?.doa?.toDate ? moment(searchValues?.doa?.toDate).format('MM-DD-YYYY') : '',
      "CaseType": arrayToCommaSeparatedString(searchValues?.selectedCaseType),
      "CaseStatus": arrayToCommaSeparatedString(searchValues?.selectedCaseStatus),
      'SearchKeyword': searchValues?.searchText,
      'InsuranceName': arrayToCommaSeparatedString(searchValues?.selectedInsurance),
    }
    try {
      let result = await getPatientList(payload);
      if (result != null) {
        if (!result?.success) {
          hideLoader()
          toast.error("Something went wrong!");
        } else {
          if (result.success) {
            setPatientListData(result?.response?.receiveObj?.data?.data)
          };
          hideLoader()
        }
      }
      else {
        hideLoader()
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader()
      toast.error(error?.response?.receiveObj?.message || "Something went wrong!");
      console.log(error);

    }
  }

  const arrayToCommaSeparatedString = (array) => {
    return Array.isArray(array) ? array.join(',') : '';
  };

  // useEffect for initial page load and whenever searchValues, pageIndex, or pageSize change
  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render to avoid double call
      isFirstRender.current = false;
    } else {
      getListData(pageIndex, pageSize, searchValues);
    }
  }, [pageIndex, pageSize, searchValues]);// Dependencies include searchValues, pageIndex, and pageSize


  useEffect(() => {
    caseTypeList();
    caseStatusList();
    insuranceList();

    getListData(pageIndex, pageSize, searchValues);
  }, []);//

  const handlePageIndexChange = (index) => {
    setPageIndex(index);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
  };

  const onSearchChange = (newSearchValues) => {
    setPageIndex(1); // Reset to first page when search values change
    setSearchValues((prevValues) => {
      const prevSelectedValues = {
        searchText: prevValues.searchText,
        selectedCaseType: prevValues.selectedCaseType,
        selectedCaseStatus: prevValues.selectedCaseStatus,
        selectedInsurance: prevValues.selectedInsurance,
        opened: prevValues.opened,
        doa: prevValues.doa,
        dob: prevValues.dob,
        insurance: prevValues.insurance
      };

      const updatedSearchValues = {
        ...prevSelectedValues,
        ...newSearchValues,
      };

      return updatedSearchValues;
    });
  };

  const handleGenerateExcel = async () => {
    showLoader()
    const payload = {
      "userUid": profile.userUid,
      "pageIndex": "1",
      "pageSize": patientListData?.totalCount?.toString(),
    }
    try {
      let result = await getPatientList(payload);
      if (result != null) {
        if (!result?.success) {
          toast.error("Something went wrong!");
          hideLoader()
        } else {
          if (result.success) {
            const data = result?.response?.receiveObj?.data?.data?.patientAttorneyRecords;
            // Check if data exists
            if (data) {
              // Format date fields (doa, dob, dos) in the data
              const formattedData = data.map(record => ({
                ...record,
                doa: record.doa
                  ? moment(record.doa).format("MM/DD/YYYY") // Format 'doa' to dd/mm/yyyy
                  : record.doa,
                dob: record.dob
                  ? moment(record.dob).format("MM/DD/YYYY") // Format 'dob' to dd/mm/yyyy
                  : record.dob,
                dos: record.dos
                  ? moment(record.dos).format("MM/DD/YYYY") // Format 'dos' to dd/mm/yyyy
                  : record.dos,
              }));
              arrayToExcel.convertArrayToTable(
                reportsTableHeaders,
                formattedData,
                "PatientReport.xlsx"
              );
            }
          }

          hideLoader()

        }
      }
      else {
        hideLoader()
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader()
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <div className="row" style={{ zIndex: 1 }}>
        <div className="col-12 col-sm-12 ">
          <div className=" mx-auto mt-2">
            <div className="mx-4 flex items-center justify-between" >
              <PatientListFilters
                onSearchChange={onSearchChange}
                caseType={casTypeData}
                caseStatus={caseStatusData}
                insurance={insuranceData}
                setSearchValues={setSearchValues}
              />
              {
                patientListData?.patientAttorneyRecords?.length > 0 ? (
                  <>
                    <div className="mt-[27px]">
                      <i
                        className="fas fa-file-excel text-cadetblue text-3xl cursor-pointer"
                        title="Excel"
                        onClick={() => handleGenerateExcel()}
                      ></i>
                    </div>
                  </>
                ) : null
              }
            </div>
          </div>
          <div class="overflow-x-auto w-full flex flex-col min-h-[200px] my-4">
            <Table columns={columns} data={patientListData?.patientAttorneyRecords} highlightOnHover

            />
          </div>
          <div className="col-12 col-sm-12 ">
            {patientListData?.patientAttorneyRecords?.length > 0 && (
              <Pagination
                className="pagination-bar"
                totalCount={patientListData?.totalCount}
                pageSize={patientListData?.pageSize}
                currentPage={patientListData?.pageIndex}
                onPageIndexChange={(value) => handlePageIndexChange(value)}
                onPageSizeChange={(value) => handlePageSizeChange(value)}
              />
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <PatientDetailsModal
          closeModal={() => closeModal()}
          isOpen={isOpen}
          caseNo={selectedRow?.caseNo}
          userUid={profile.userUid}
          selectedRow={selectedRow}
        />
      )
      }

      {
        isOpenDocument &&
        <DocumentManager
          setIsModalOpen={() => closeDocumentModal()}
          isModalOpen={isOpenDocument}
          caseNo={selectedRow?.caseNo}
          caseId={selectedRow?.caseId}
          userUid={profile.userUid}
          companyId={selectedRow?.companyId}
          currentTableRow={selectedRow}
        />
      }

    </>
  );
};

export default PatientList;
