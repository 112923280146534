import jsPDF from "jspdf";
import {PDFDocument} from "pdf-lib";
import { saveAs } from "file-saver";


export function FileToBase64(blob) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
  });
}

export const buildUrl = (url, parameters) => {
  var qs = "";
  for (var key in parameters) {
    var value = parameters[key];
    if (value && value !== "All") {
      qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + "?" + qs;
  }
  return url;
};

export const convertDateTO = (dat) => {
  const date = new Date(dat);
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return `${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}-${y}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const imgToPdfBuffer = (blob) => {
  let image;

  image = document.createElement("img");
  image.src = URL.createObjectURL(blob);
  image.onload = function () {
    URL.revokeObjectURL(image.src);
  };

  let doc = new jsPDF();
  const imgProps = doc.getImageProperties(image.src);
  const pdfWidth = doc.internal.pageSize.getWidth();
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  doc.addImage(
    image.src,
    image.imageType,
    0,
    pdfHeight / 2,
    pdfWidth,
    pdfHeight
  );
  return doc.output("arraybuffer");
};

export const GenerateMergedPdf = async (PdfArray, FileName, action) => {
  if (PdfArray.length > 0) {
    const mergedPdf = await PDFDocument.create();
    for (let i = 0; i < PdfArray.length; i++) {
      let tempPdf = await PDFDocument.load(PdfArray[i], {
        ignoreEncryption: true,
      });
      const copiedPages = await mergedPdf.copyPages(
        tempPdf,
        tempPdf.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    const mergedPdfFile = await mergedPdf.save();
    let file = new Blob([mergedPdfFile], {
      type: "application/pdf",
    });

    if (action === "download") {
      saveAs(
        file,
        `${FileName.replaceAll(" ", "")}_${new Date().toLocaleDateString(
          "en-US"
        )}.pdf`
      );
    } else if (action === "print") {
      window.open(URL.createObjectURL(file));
    } else {
      return mergedPdfFile;
    }
  }
};