import { postWithoutTOken } from "../apiUtils";
const baseURL = process.env.REACT_APP_API_URL

export const login = async (data) => {
  try {
    const response = await postWithoutTOken(`${baseURL}User/Login`,
      "",
      data,
      false,
      false
    )
    return {response : response};

  } catch (error) {
    return { success: false, response: error };
  }
};