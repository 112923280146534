export const STORAGE_TOKEN_KEY = "_token";
export const STORAGE_PROFILE_KEY = "_profile";
export const STORAGE_AUTH_KEY = "isAuth";
export const COMMON_SERVICE_AUTH_TOKEN = "_common_service_token";


export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength4 = minLength(4);

/* eslint-disable */
export const FaxRegex =
  /\+1(|\.|\-)[2-9][0-9]{2}(|\.|\-)[0-9]{3}(|\.|\-)[0-9]{4}/;
/* eslint-enable */