// src/components/ProtectedRoute.js
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Header from 'shared/Header';
import Footer from 'shared/Footer';
import { getLocalStorage } from 'services/storage';
import * as keys from "utils/constant";


const ProtectedRoute = ({ children }) => {
  const isAuthenticated = getLocalStorage(keys.STORAGE_AUTH_KEY);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header />
      {children} 
      <Footer />
    </>
  );
};

export default ProtectedRoute;