import { usePagination, DOTS } from "./usePagination";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { AiFillCaretUp } from "react-icons/ai";

const Pagination = (props) => {
  const {
    onPageIndexChange,
    onPageSizeChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    HeaderComponent
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const totalPageCount = Math.ceil(totalCount / pageSize);


  return (
    <nav
      aria-label="Page navigation example"
      className="flex flex-col md:flex-row justify-between items-center mt-4 -mb-5 space-y-4 md:space-y-0 md:space-x-4 ml-4"
    >
      {/* Record Count */}
      <div className="text-gray-700">
        <span>{`Record Count: ${totalCount}`}</span>
      </div>

      {/* Pagination */}
      <div className="flex items-center">
        <ul className="flex items-center space-x-2">
          {/* Page Size Dropdown */}
          <li className="relative">
            <Menu>
              <MenuButton className="flex items-center space-x-1 text-gray-700 border border-gray-300 rounded px-3 py-1">
                <p>Show</p>
                <div className="flex items-center">
                  {pageSize}
                  <AiFillCaretUp className="ml-1" size={10} />
                </div>
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 mt-2 w-20 bg-white border border-gray-200 rounded shadow-lg z-50">
                  {[10, 15, 25, 50, 100].map((item) => (
                    <MenuItem key={item}>
                      <p
                        className="cursor-pointer px-4 py-2 text-gray-700 hover:bg-cadetblue"
                        onClick={() => onPageSizeChange(item)}
                      >
                        {item}
                      </p>
                    </MenuItem>
                  ))}
                </MenuItems>
              </Transition>
            </Menu>
          </li>

          {/* Previous Page */}
          <li
            className={`${currentPage === 1 ? "pointer-events-none text-gray-400" : ""
              }`}
            onClick={() => onPageIndexChange(currentPage - 1)}
          >
            <span className="px-3 py-1 border rounded text-gray-700 hover:bg-gray-100">
              <i className="fa fa-angle-left" />
            </span>
          </li>

          {/* Pagination Numbers */}
          {paginationRange &&
            paginationRange.map((pageNumber) =>
              pageNumber === DOTS ? (
                <li key={pageNumber} className="text-gray-500 mx-2">
                  &#8230;
                </li>
              ) : (
                <li
                  key={pageNumber}
                  className={`${currentPage === pageNumber
                      ? "bg-cadetblue text-white"
                      : "text-gray-700 hover:bg-gray-100"
                    } px-3 py-1 border rounded`}
                  onClick={() => onPageIndexChange(pageNumber)}
                >
                  <span>{pageNumber}</span>
                </li>
              )
            )}

          {/* Next Page */}
          <li
            className={`${currentPage === totalPageCount ? "pointer-events-none text-gray-400" : ""
              }`}
            onClick={() =>
              currentPage === totalPageCount ? "" : onPageIndexChange(currentPage + 1)
            }
          >
            <span className="px-3 py-1 border rounded text-gray-700 hover:bg-gray-100">
              <i className="fa fa-angle-right" />
            </span>
          </li>
        </ul>
      </div>
    </nav>


  );
};

export default Pagination;
