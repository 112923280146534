
import React, { useEffect } from "react";
import MultiSelectWithCheckbox from "components/controls/multiselectComponent";
import { showReportStatus } from "./data";
import { useState } from "react";
import DateRange from "shared/DateRange";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";

const PatientListFilters = (props) => {
  const { onSearchChange, caseType, setSearchValues, caseStatus, insurance } = props;
  const [searchText, setSearchText] = useState("");
  const [selectedCaseType, setSelectedCaseType] = useState('');
  const [selectedCaseStatus, setSelectedCaseStatus] = useState('');
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [duration, setDuration] = useState('');
  const [doa, setDoa] = useState(null);
  const [dob, setDob] = useState(null);
  const [opened, setOpened] = useState(null);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const fromDate = startOfMonth(addMonths(new Date(), -3));
  const toDate = new Date();
  const defaultRange = [fromDate, toDate];

  const handleDropdownChange = async (option, event) => {
    const value = event.map((item) => item.value);
    switch (option) {
      case "selectedCaseStatus":
        setSelectedCaseStatus(value);
        break;
      case "selectedCaseType":
        setSelectedCaseType(value);
        break;
      case "selectedInsurance":
        setSelectedInsurance(value);
        break;
      default:
        break;
    }
    onSearchChange({ [option]: value });
  };

  const onChangeSearchName = (event) => {
    const value = event.target.value;
    setSearchText(value);

    // Trigger immediate API call if the field is cleared
    if (value === "") {
      onSearchChange({ searchText: "" });
    }
  };

  // useEffect for implementing the debounce functionality
  useEffect(() => {
    if (searchText === "") {
      return; // Avoid setting a debounce if the field is empty
    }

    // Set a timeout to update the debounced value after 2.5 seconds
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 2500); // 2500ms = 2.5 seconds

    // Clear the timeout if the value changes within the delay period
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  // Trigger the actual search when debouncedSearchText changes
  useEffect(() => {
    if (debouncedSearchText !== "") {
      onSearchChange({ searchText: debouncedSearchText });
    }
  }, [debouncedSearchText]);

  const handleDate = (val, name) => {
    if (!val) {
      onSearchChange({
        opened: null,
        doa: null,
        dob: null,
      });
      return;
    }

    // Check if either start or end date is incomplete or invalid
    if (!isValidCompleteDate(val)) {
      switch (name) {
        case "opened":
          setOpened(null);
          break;

        case "doa":
          setDoa(null);
          break;

        case "dob":
          setDob(null);
          break;

        default:
          break;
      }
      return;
    }



    const [startDate, endDate] = val;

    // Validate the date range
    if (new Date(startDate) > new Date(endDate)) {
      return; // Handle invalid date range
    }

    const newSearchEventDate = {
      fromDate: startDate,
      toDate: endDate,
    };

    // Use a switch statement to handle specific date types
    switch (name) {
      case "opened":
        onSearchChange({
          opened: newSearchEventDate,
          doa: null,
          dob: null,
        });
        break;

      case "doa":
        onSearchChange({
          opened: null,
          doa: newSearchEventDate,
          dob: null,
        });
        break;

      case "dob":
        onSearchChange({
          opened: null,
          doa: null,
          dob: newSearchEventDate,
        });
        break;

      default:
    }
  };

  const isValidCompleteDate = (dateArray) => {

    if (!Array.isArray(dateArray) || dateArray.length !== 2) return false;

    return dateArray.every((dateString) => {
      if (!dateString) return false;

      // Parse and check if it's a valid date
      const parsedDate = new Date(dateString);
      return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
    });
  };

  return (
    <React.Fragment>
      <div className="row flex flex-wrap items-center gap-2">
        <div className="form-group sm:w-auto">
          <label htmlFor="staticEmail2" className="block font-bold">
            Case#Patient#Claim#
          </label>
          <input
            type="text"
            onChange={(e) => onChangeSearchName(e)}
            value={searchText || ""}
            name="searchText"
            id="recipient-name-reports"
            placeholder="Search By Keyword"
            className="w-full sm:w-auto border border-gray-300 rounded-sm p-2 text-sm h-9 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="form-group mx-sm-1" style={{ zIndex: 2 }}>
          <label>
            <b>Case Type</b>
          </label>
          <MultiSelectWithCheckbox
            singleSelect={false}
            options={caseType}
            onChange={handleDropdownChange.bind(this, "selectedCaseType")}
            value={Array.isArray(selectedCaseType) ? selectedCaseType.map(value => {
              return caseType.find(option => option.value === value);
            }) : []}
            valueLabel={"case Type"}
          />
        </div>

        <div className="form-group mx-sm-1" style={{ zIndex: 6 }}>
          <label>
            <b>Case Status</b>
          </label>
          <MultiSelectWithCheckbox
            singleSelect={false}
            options={caseStatus}
            onChange={handleDropdownChange.bind(this, "selectedCaseStatus")}
            value={Array.isArray(selectedCaseStatus) ? selectedCaseStatus.map(value => {
              return caseStatus.find(option => option.value === value);
            }) : []}
            valueLabel={"Case Status"}
          />
        </div>
        <div className=" form-group mx-sm-1 dateDiv">
          <DateRange
            width={"190px"}
            value={opened}
            label={<b>Opened</b>}
            onChangeDate={(val) => handleDate(val, 'opened')}
            defaultRange={defaultRange}
          />
        </div>
        <div className="flex flex-col items-start form-group mx-sm-1 dateDiv">
          <DateRange
            width={"190px"}
            value={doa}
            label={<b>DOA</b>}
            onChangeDate={(val) => handleDate(val, 'doa')}
          />
        </div>

        <div className="flex flex-col items-start form-group mx-sm-1 dateDiv">
          <DateRange
            width={"190px"}
            value={dob}
            label={<b>DOB</b>}
            onChangeDate={(val) => handleDate(val, 'dob')}
          />
        </div>
        <div className="flex flex-col items-start form-group mx-sm-1 dateDiv">
          <label htmlFor="staticEmail2" className="block font-bold">
            Insurance
          </label>
          <MultiSelectWithCheckbox
            singleSelect={false}
            options={insurance}
            onChange={handleDropdownChange.bind(this, "selectedInsurance")}
            value={Array.isArray(selectedInsurance) ? selectedInsurance.map(value => {
              return insurance.find(option => option.value === value);
            }) : []}
            valueLabel={"Insurance"}
          />
        </div>
      </div>

    </React.Fragment>
  );
};

export default PatientListFilters;
