import { jwtDecode } from "jwt-decode";
import * as storage from "./storage";
import * as constant from "../utils/constant";
const decodeJwt = (token) => {
  return jwtDecode(token);
};

const getTokenExpirationDate = (tokenObject) => {
  const date = tokenObject?.expiresOn;
  if (!date) {
    return null;
  }

  const dateUtc = new Date(date).getTime();
  var d = new Date(dateUtc);
  d.setUTCSeconds(dateUtc);
  return new Date(date);
};

const isTokenExpired = () => {
  const tokenObject = storage.getLocalStorage(constant.STORAGE_TOKEN_KEY);
  const date = getTokenExpirationDate(tokenObject);
  if (!date) {
    return true;
  }
  return new Date() >= date;
};

const isCommonTokenExpired = () => {
  const tokenObject = storage.getLocalStorage(constant.COMMON_SERVICE_AUTH_TOKEN);
  const date = getTokenExpirationDate(tokenObject);
  if (!date) {
    return true;
  }
  return new Date() >= date;
};

const getTokenExpireyPendingTime = () => {
  const tokenObject = storage.getLocalStorage(constant.STORAGE_TOKEN_KEY);
  const date = getTokenExpirationDate(tokenObject);
  if (date) {
    var diff = (date.getTime() - new Date().getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
};

export { decodeJwt, isTokenExpired, getTokenExpireyPendingTime,isCommonTokenExpired };
