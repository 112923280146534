import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthData } from '../../redux/slice/index';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { deleteLocalStorage } from 'services/storage';
import * as keys from "utils/constant";

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(""); // Track active menu
    const dropdownRef = useRef(null);

    const toggleMenu = () => setIsOpen((prev) => !prev);

    const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

    const closeDropdown = () => setIsDropdownOpen(false);

    const logOut = () => {
        deleteLocalStorage(keys.STORAGE_TOKEN_KEY);
        deleteLocalStorage(keys.STORAGE_AUTH_KEY);
        deleteLocalStorage(keys.STORAGE_PROFILE_KEY);
        deleteLocalStorage(keys.COMMON_SERVICE_AUTH_TOKEN);
        navigate('/');
        dispatch(
            setAuthData({
                isAuthenticated: false,
                token: null,
                profile: null,
                userUid: null,
                commonServiceToken: null,
            })
        );
    };
    useEffect(() => {
        if (location.pathname === "/patientList" || location.pathname === "/") {
            setActiveMenu("Patient Report");
        } else if (location.pathname === "/visitList") {
            setActiveMenu("Visit Report");
        }
    }, [location.pathname]);
    // Close dropdown on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <nav className="sticky top-0 z-10 bg-lightblue">
            <div className="mx-auto">
                <div className="flex items-center h-12">
                    {/* Logo Section */}
                    <div className="flex items-center w-2/12 logo-width">
                        <Link className="logo-horizontal border-r-2 border-[rgb(71,149,148)] h-[42px]" to="">
                            <img
                                src={require("../../assets/image/brand/logo.png")}
                                className="pb-1 h-10 w-auto sm:h-12 sm:w-auto header-brand-img desktop-logo"
                                alt="logo"
                            />
                        </Link>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="md:flex w-4/12 items-center relative" ref={dropdownRef}>
                        {/* Main Button */}
                        <button
                            className={`hover:border-white bg-cadetblue text-white px-2 py-1 rounded-md text-sm font-medium border-2 border-transparent transition-all ${activeMenu === "patient" ? "bg-darkblue" : ""
                                }`}
                            onClick={() => {
                                toggleDropdown();
                            }}
                        >
                            Patient
                        </button>

                        {/* Dropdown Menu */}
                        {isDropdownOpen && (
                            <div className="absolute top-full left-0 mt-2 bg-white border rounded-md shadow-lg w-38 z-10">
                                <Link
                                    to="/patientList"
                                    className={`block px-4 py-2 text-sm ${location.pathname === "/patientList"
                                        ? "text-white bg-cadetblue"
                                        : "text-gray-700 hover:bg-cadetblue hover:text-white"
                                        }`}
                                    onClick={() => {
                                        closeDropdown();
                                    }}
                                >
                                    Patient Report
                                </Link>
                                <Link
                                    to="/visitList"
                                    className={`block px-4 py-2 text-sm ${location.pathname === "/visitList"
                                        ? "text-white bg-cadetblue"
                                        : "text-gray-700 hover:bg-cadetblue hover:text-white"
                                        }`}
                                    onClick={() => {
                                        closeDropdown();
                                    }}
                                >
                                    Visit Report
                                </Link>
                            </div>
                        )}
                    </div>

                    <div className="w-4/12 mr-16 logo-width">
                        <span className="text-black font-bold text-lg attorney-portal font-size">
                            {activeMenu}
                        </span>
                    </div>

                    <div className="w-2/12 flex justify-end px-4 button-hidden">
                        <button
                            className="hover:border-white bg-cadetblue text-white px-2 py-1 border-2 border-transparent transition-all rounded text-sm font-medium"
                            onClick={logOut}
                        >
                            <i className="fa fa-sign-out" title="Logout"></i>
                        </button>
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="flex items-center show-bar">
                        <button
                            onClick={toggleMenu}
                            className="text-black focus:outline-none"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Links */}
            {isOpen && (
                <div className="bg-[#c8ebef]">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <Link
                            to="/patientList"
                            className={`text-black hover:text-white hover:bg-cadetblue block px-3 py-2 rounded-md text-base font-medium ${activeMenu === "patientList" ? "bg-darkblue text-white" : ""
                                }`}
                            onClick={() => {
                                setActiveMenu("patientList");
                                setIsOpen(false);
                            }}
                        >
                            Patient List
                        </Link>
                        <Link
                            to="/visitList"
                            className={`block px-4 py-2 text-sm ${activeMenu === "visitList" ? "bg-darkblue text-white" : "text-gray-700 hover:bg-cadetblue hover:text-white"
                                }`}
                            onClick={() => {
                                setActiveMenu("visitList");
                                setIsOpen(false);
                            }}
                        >
                            Visit Report
                        </Link>
                        <Link
                            to="/"
                            className="text-black hover:text-white hover:bg-cadetblue block px-3 py-2 rounded-md text-base font-medium"
                            onClick={logOut}
                        >
                            Logout
                        </Link>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
