// src/components/PublicRoute.js
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getLocalStorage } from 'services/storage';
import * as keys from "utils/constant";

const PublicRoute = ({ children }) => {
  const isAuthenticated = getLocalStorage(keys.STORAGE_AUTH_KEY);

  return !isAuthenticated ? (
    <>
      <div className="content-container">
        {children}
      </div>
    </>
  ) : (
    <Navigate to="/patientList" />
  );
};

export default PublicRoute;
