import axios from "axios";
import * as tokenFunction from "../decodeEncode";
import * as storage from "../../services/storage";
import * as constant from "../../utils/constant";
import { deleteLocalStorage } from 'services/storage';
import * as keys from "utils/constant";


const logout = () => {
    deleteLocalStorage(keys.STORAGE_TOKEN_KEY)
    deleteLocalStorage(keys.STORAGE_AUTH_KEY)
    deleteLocalStorage(keys.STORAGE_PROFILE_KEY)
    deleteLocalStorage(keys.COMMON_SERVICE_AUTH_TOKEN)
    window.location.href = '/';

}

const uninterceptedAxiosInstance = axios.create();
export const get = (url, param, isSkipLoader = false) => {
    if (tokenFunction.isTokenExpired()) {
        logout()
    } else {
        return axios
            .get(url + param, { isSkipLoader: isSkipLoader })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
};


export const getFromCommmonService = (url, param, isSkipLoader = false) => {

    let commonServiceToken = storage.getLocalStorage(
        constant.COMMON_SERVICE_AUTH_TOKEN
    );
    if (!commonServiceToken || tokenFunction.isCommonTokenExpired()) {
        logout();
    } else {
        return uninterceptedAxiosInstance
            .get(url + param, {
                isSkipLoader: isSkipLoader,
                headers: {
                    Authorization: `Bearer ${commonServiceToken.token}`,
                },
            })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
}

export const getFile = (url, param, isSkipLoader = false) => {
    if (tokenFunction.isTokenExpired()) {
        logout();
    } else {
        return axios
            .get(url + param, {
                responseType: "arraybuffer",
                isSkipLoader: isSkipLoader,
            })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
}

export const getAsync = async (url, param, isSkipLoader = false) => {
    try {
        let data = await axios
            .get(url + param, { isSkipLoader: isSkipLoader })
            .then((response) => {
                return { success: true, receiveObj: response };
            })
            .catch((error) => {
                return { success: false, receiveObj: error };
            });

        return data;
    } catch (ex) {
        return { success: false, receiveObj: ex };
    }
};

export const post = (
    url,
    param,
    data,
    isSkipLoader = false,
    tokenValidationRequired = true
) => {
    if (tokenValidationRequired && tokenFunction.isTokenExpired()) {
        logout();
    } else {
        return axios
            .post(url + param, data, { isSkipLoader: isSkipLoader })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
};


export const postAsync = async (url, param, value, isSkipLoader = false) => {
    try {
        let data = await axios
            .post(url + param, value, { isSkipLoader: isSkipLoader })
            .then((response) => {
                return { success: true, receiveObj: response };
            })
            .catch((error) => {
                return { success: false, receiveObj: error };
            });

        return data;
    } catch (ex) {
        return { success: false, receiveObj: ex };
    }
};

export const postWithoutTOken = (
    url,
    param,
    data,
    isSkipLoader = false,
    tokenValidationRequired = true
) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                url + param,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_ORIGIN,
                        "Access-Control-Allow-Methods":
                            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Origin, Content-Type, X-Auth-Token",
                    },
                },
                { isSkipLoader: isSkipLoader, tokenEnabled: false }
            )
            .then((response) => {
                resolve({ success: true, receiveObj: response });
            })
            .catch((error) => {
                reject({ success: false, receiveObj: error });
            });
    });
};

export const postWithFiles = (
    url,
    param,
    data,
    isSkipLoader = false,
    tokenValidationRequired = true
) => {
    return axios
        .post(url + param, data, {
            headers: {
                "Content-Type": undefined, // Allows axios to auto-detect the boundary
            },
            isSkipLoader: isSkipLoader,
            tokenEnabled: false,
        })
        .then((response) => ({ success: true, receiveObj: response }))
        .catch((error) => ({ success: false, receiveObj: error }));
};


export const put = (
    url,
    param,
    data,
    isSkipLoader = false,
    tokenValidationRequired = true
) => {
    if (tokenValidationRequired && tokenFunction.isTokenExpired()) {
        logout();
    } else {
        return axios
            .put(url + param, data, { isSkipLoader: isSkipLoader })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
};


export const GetResponseBlob = (url, param, isSkipLoader = false) => {
    let commonServiceToken = storage.getLocalStorage(
        constant.COMMON_SERVICE_AUTH_TOKEN
    );

    if (!commonServiceToken || tokenFunction.isCommonTokenExpired()) {
        logout();
    } else {
        return uninterceptedAxiosInstance
            .get(url + param, {
                responseType: "blob",
                headers: { Authorization: `Bearer ${commonServiceToken?.token}` },
            })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
};


export const GetResponseBlobPromise = (url, param, isSkipLoader = false) => {
    let commonServiceToken = storage.getLocalStorage(
        constant.COMMON_SERVICE_AUTH_TOKEN
    );
    if (!commonServiceToken || tokenFunction.isCommonTokenExpired()) {
        logout();
    } else {
        return uninterceptedAxiosInstance
            .get(url + param, {
                responseType: "blob",
                headers: { Authorization: `Bearer ${commonServiceToken?.token}` },
            })
            .catch((err) => err.response);
    }
};

export const postToCommonService = (
    url,
    param,
    data,
    isSkipLoader = false,
    tokenValidationRequired = true
) => {
    if (tokenValidationRequired && tokenFunction.isCommonTokenExpired()) {
        logout();
    } else {
        let commonServiceToken = storage.getLocalStorage(
            constant.COMMON_SERVICE_AUTH_TOKEN
        );

        return uninterceptedAxiosInstance
            .post(url + param, data, {
                isSkipLoader: isSkipLoader,
                headers: { Authorization: `Bearer ${commonServiceToken?.token}` },
            })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
};


export const postWithFileToCommonService = (
    url,
    param,
    data,
    isSkipLoader = false,
    tokenValidationRequired = true
) => {
    if (tokenValidationRequired && tokenFunction.isCommonTokenExpired()) {
        logout();
    } else {
        let commonServiceToken = storage.getLocalStorage(
            constant.COMMON_SERVICE_AUTH_TOKEN
        );

        return uninterceptedAxiosInstance
            .post(url + param, data, {
                isSkipLoader: isSkipLoader,
                headers: {
                    Authorization: `Bearer ${commonServiceToken?.token}`,
                },
            })
            .then((response) => ({ success: true, receiveObj: response }))
            .catch((error) => ({ success: false, receiveObj: error }));
    }
};
