import { useState } from "react";
import Select, { components } from "react-select";

const ValueContainer = ({
    children,
    ...props
}) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
        const plural = values.length === 1 ? "" : "s";
        values = `${values.length} item${plural}`;
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} className="mr-2" />
            {" "}{children}
        </components.Option>
    );
};




export default function MultiSelectWithCheckbox({ options, onChange, valueLabel, value, singleSelect, customStyles, ...rest }) {
    const handleValueSelect = (options) => {
        onChange(options)
    }

    return (
        <>
            {singleSelect ? (
                <Select
                    isDisabled={true}
                    options={options}
                    value={value || {}}
                    closeMenuOnSelect={false}
                    styles={customStyles}
                    {...rest}
                />

            ) : (
                <Select
                    isSearchable
                    value={value || []}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => { handleValueSelect(options) }}
                    options={options}
                    components={{
                        Option: InputOption, ValueContainer
                    }}
                    styles={customStyles}
                    {...rest}
                />
            )
            }
        </>

    );
}
