/* eslint-disable no-redeclare */
import { convertDateTO } from "utils/utility";
import * as XLSX from "xlsx";

var wscols = [
  { width: 28.75 },
  { width: 10.75 },
  { width: 10.75 },
  { width: 10.75 },
  { width: 10.63 },
  { width: 6.13 },
  { width: 34.50 },
  { width: 17.38 },
  { width: 10.75 },
  { width: 50 },
];

export const arrayToExcel = (function () {
  function exportExcel(tableText, filename) {
    const wb = XLSX.utils.book_new();
    /* create element from the source */
    var elt = document.createElement("div");
    elt.innerHTML = tableText;
    document.body.appendChild(elt);
    /* generate worksheet */
    var ws = XLSX.utils.table_to_sheet(elt.getElementsByTagName("TABLE")[0], {
      raw: true,
      type: "string",
    });
    ws["!cols"] = wscols;
    /* remove element */
    document.body.removeChild(elt);
    XLSX.utils.book_append_sheet(wb, ws, "PatientReferralReport");
    XLSX.writeFile(wb, filename);
  }

  const createprocedureCodes = (item) => {
    return item && item.length > 0
      ? item.map((itnm) => `${itnm.typeCode}-${itnm.typeDescription}`).join(",")
      : "";
  };

  const reportsTable = (tableHeader, apiArray) => {
    var table = "<table><tr>";
    for (var i = 0; i < tableHeader.length; i++) {
      table += "<td><b>" + tableHeader[i] + "</b></td>";
    }
    table += "</tr><tbody>";
    // var tableRows = "";
    for (var i = 0; i < apiArray.length; i++) {
      table += "<tr><td>" + apiArray[i]?.patientName + "</td>";
      table += "<td>" + convertDateTO(apiArray[i]?.dob) + "</td>";
      table += "<td>" + convertDateTO(apiArray[i]?.accidentDate) + "</td>";
      table += "<td>" + convertDateTO(apiArray[i]?.dateOfService) + "</td>";
      table += "<td>" + apiArray[i]?.visitStatus + "</td>";
      table += "<td>" + apiArray[i]?.caseType + "</td>";
      table += "<td>" + apiArray[i]?.insuranceName + "</td>";
      table += "<td>" + apiArray[i]?.claimNumber + "</td>";
      table += "<td>" + apiArray[i]?.policyNumber + "</td>";
      table +=
        "<td>" + createprocedureCodes(apiArray[i]?.procedureCodes) + "</td>";

      table += "</tr>";
    }
    table += "</tbody></table>";
    return table;
  };

  //STEP 1: Convert Array to HTML Table.
  return {
    convertArrayToTable: async (tableHeader, apiArray, fileName) => {
      const table = reportsTable(tableHeader, apiArray, fileName);
      exportExcel(table, fileName);
    },
  };
})();
