import React, { useEffect, useState } from "react";
import logo from "../../assets/image/brand/logo.png"
import { login } from "services/http/Login";
import * as keys from "../../utils/constant";
import * as storage from '../../services/storage';
import toast from "react-hot-toast";
import { useDispatch } from 'react-redux';
import { setAuthData } from '../../redux/slice/index'
import { useLoader } from "services/loaderService";
import { Link, useNavigate } from "react-router-dom";



const Login = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: "", password: "" });
  const { showLoader, hideLoader } = useLoader();
  const [showPassword, setShowPassword] = useState(false);
  const Navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    const payload = { showPassword: false, name: formData.name, password: formData.password };
    try {
      let result = await login(payload);
      if (result) {
        if (result?.response?.success) {
          const token = result?.response?.receiveObj?.data?.data?.token;
          const commonServiceToken =
            result?.response?.receiveObj?.data?.data?.commonServiceToken;
          const profile = result?.response?.receiveObj?.data?.data;
          if (result?.response?.success && token && profile) {
            storage.setLocalStorage(keys.STORAGE_AUTH_KEY, true);
            storage.setLocalStorage(keys.STORAGE_TOKEN_KEY, token);
            storage.setLocalStorage(keys.STORAGE_PROFILE_KEY, profile);
            storage.setLocalStorage(
              keys.COMMON_SERVICE_AUTH_TOKEN,
              commonServiceToken
            );
            // Dispatch Redux action
            dispatch(
              setAuthData({
                isAuthenticated: true,
                token,
                profile: profile,
                // userUid,
                commonServiceToken,
              })
            );
            hideLoader()
            Navigate('/patientList')
            // window.location.href = "/patientList";

            return {
              isAuthenticated: true,
              token: token,
              profile: profile,
              commonServiceToken: commonServiceToken,
            };

          }
        } else {
          hideLoader();
          toast.error(result?.response?.receiveObj?.response?.data?.message || result?.response?.receiveObj?.message);
        }
      } else {
        hideLoader();
        toast.error(result?.response?.receiveObj?.data?.message || "Something went wrong!");
      }
    } catch (e) {
      hideLoader();
      toast.error('Something went wrong!');
      console.error(e);
    }
  };


  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const showPasswordHandle = () => {
    setShowPassword(!showPassword);
  };


  useEffect(() => {
    document.getElementById("username")?.focus();
    return () => {
      // Cleanup if needed
    };
  }, []);

  // const forgotPasswordTab = () => {
  //   window.open("https://www.greenyourbills.com/Bill_Sys_ForgotPassword.aspx");
  // }

  const validateForm = () => !formData.name || !formData.password;

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-cadetblue ">
        <div className="w-80 max-w-md p-6 bg-white rounded-lg shadow-md">
          <div className="flex justify-center mb-6">
            <img src={logo} alt="Logo" className="header-brand-img1" />
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <div className="flex items-center">
                <span className="mr-3">
                  <i className="fa-solid fa-user"></i>
                </span>
                <input
                  id="username"
                  name="name"
                  type="text"
                  placeholder="Username"
                  onChange={handleTextChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-[rgb(95,158,160)]"
                />
              </div>
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="flex items-center">
                <span onClick={showPasswordHandle} className="cursor-pointer mr-2">
                  {showPassword ? (
                    <i className="fa-solid fa-eye-slash" aria-hidden="true"></i>
                  ) : (
                    <i className="fa-solid fa-eye" aria-hidden="true"></i>
                  )}
                </span>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handleTextChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-[rgb(95,158,160)]"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={validateForm()}
                className="w-40 h-11  text-lg font-bold text-white bg-cadetblue rounded-lg hover:bg-cadetblue focus:outline-none focus:ring focus:ring-[rgb(95,158,160)] disabled:bg-gray-300"
              >
                Login
              </button>
            </div>
            {/* <div className="mt-2 flex justify-center">
              <Link onClick={forgotPasswordTab}>Forgot Password</Link>
            </div> */}
          </form>

        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default Login;
