import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineDelete } from "react-icons/ai";
import { TbFile } from "react-icons/tb";
import { toast } from "react-hot-toast";
import { formatBytes } from "./utility";

const Dropzone = ({ files, setFiles }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
  });

  useEffect(() => {
    setFiles(acceptedFiles);
    // eslint-disable-next-line
  }, [acceptedFiles]);

  useEffect(() => {
    fileRejections.forEach((item) =>
      toast.error(
        `Selected file (${item?.file?.name}) extension is not supported.`
      )
    );
  }, [fileRejections]);

  return (
    <>
      <div
        style={{ marginLeft: 20 }}
        {...getRootProps({
          className:
            "dropzone tw-border-2 tw-border-gray-400 hover:tw-border-primary-color tw-rounded-md tw-mx-5",
        })}
      >
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center tw-my-2">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <p className="dropzone-content">
              Drag’&’drop some files here, or click to select files
            </p>
          )}
          <button
            type="button"
            className="btn tw-p-1 tw-m-2 tw-bg-primary-color-75 hover:tw-bg-primary-color tw-text-white tw-font-semibold"
          >
            Click to select files
          </button>
        </div>
      </div>
      {files?.length > 0 && (
        <aside>
          <p style={{ marginTop: 5, marginLeft: 40 }}>Uploaded Files:</p>
          <ul>
            {files?.map((file) => (
              <li
                className="tw-flex tw-m-2"
                style={{ marginLeft: 50 }}
                key={file.path}
              >
                <TbFile
                  color="var(--primary-bg-color-75)"
                  style={{ marginTop: 3 }}
                />
                {file.path} - {formatBytes(file.size)}
                <AiOutlineDelete
                  size={18}
                  color="red"
                  className="tw-cursor-pointer"
                  style={{ marginTop: 2 }}
                  onClick={() => {
                    setFiles(files.filter((item) => item.name !== file.name));
                  }}
                />
              </li>
            ))}
          </ul>
        </aside>
      )}
    </>
  );
};

export default Dropzone;
